import { h, render, Fragment } from "preact";

const tiers = [
    // {
    //     name: "Personal Usage",
    //     id: "tier-hobby",
    //     href: "#",
    //     priceMonthly: "$29",
    //     description: "Designed to provide flexible financing tailored to your individual needs. Recommended for those managing unexpected expenses or planning a major purchase.",
    //     features: ["Quick Application Process", "Secured Payments", "Next Day ACH Funding", "Customer Care"],
    //     mostPopular: false,
    // },
    // "Quick Recharge"
    // Designed for convenience, it helps you cover urgent expenses and get back on track without the hassle. Recommended for those running low on cash and require fast access to funds.
    {
        name: "In Need of Cash?",
        id: "offer",
        href: "/apply-now",
        description: "Lending with convenience in mind, our service helps you cover urgent expenses and get back on track without the hassle. When you're low on cash, Lucent Cash provides a reliable lending solution to help you stay afloat and move forward with confidence.",
        features: [
            "Quick Application Process",
            "Secured Payments",
            "Next Day ACH Funding",
            "Customer Care",
            "Funds are typically deposited the business day after your loan is approved",
        ],
        mostPopular: false,
    },
];

export default function Featured() {
    return (
        <div className="isolate overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
                <div className="mx-auto max-w-4xl">
                    <p className="mt-2 text-balance text-5xl font-faust font-semibold tracking-tight text-[#411f01] sm:text-6xl">
                        Making Life Easier with Easy Borrowing
                    </p>
                </div>
                <div className="relative mt-6">
                    <p className="mx-auto max-w-2xl text-pretty text-lg font-faust font-medium text-[#411f01] sm:text-xl/8">
                        Lucent Cash simplifies the borrowing process, putting straight-forward installment loans easily within reach.
                    </p>
                    <svg
                        viewBox="0 0 1208 1024"
                        className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
                    >
                        <ellipse cx={604} cy={512} rx={604} ry={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" />
                        <defs>
                            <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                                <stop stopColor="#fcddbb" />
                                <stop offset={1} stopColor="#FFF8B8" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="flow-root bg-white font-inter pb-24 sm:pb-32">
                <div className="-mt-80">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-2xl lg:grid-cols-1">
                            {tiers.map((tier) => (
                                <div
                                    key={tier.id}
                                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                                >
                                    <div>
                                        <div className="flex items-center justify-between gap-x-4">
                                            <h3 id={tier.id} className="text-lg font-semibold text-portmain-300">
                                                {tier.name}
                                            </h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-7 text-portmain-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                                            </svg>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-7 text-portmain-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg> */}
                                            {tier.mostPopular
                                                ? (
                                                    <p className="rounded-full bg-orange-600/10 px-2.5 py-1 text-xs/5 font-semibold text-portmain-300">
                                                        Most popular
                                                    </p>
                                                )
                                                : null}
                                        </div>
                                        <p className="mt-6 text-base/7 text-[#411f01]">{tier.description}</p>
                                        <ul role="list" className="mt-10 space-y-4 text-sm/6 text-[#411f01]">
                                            {tier.features.map((feature) => (
                                                <li key={feature} className="flex gap-x-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 flex-none text-portmain-300">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                    </svg>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <a
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className="transition-colors duration-300 ease-in-out mt-8 block rounded-md bg-portmain-300 px-3.5 py-2 text-center text-base font-inter text-white shadow-sm hover:bg-portmain-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-700"
                                        style="box-shadow: #f29f57 0px 10px 40px -5px;"
                                    >
                                        Apply Now
                                    </a>
                                </div>
                            ))}
                            <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                                <div className="lg:min-w-0 lg:flex-1">
                                    <h3 className="text-base/7 font-semibold text-portmain-300">Already have an account?</h3>
                                    <p className="mt-1 text-base/7 text-[#411f01]">
                                        Sign in to your account to apply online or check out what other special offers are in store for you.
                                    </p>
                                </div>
                                <a
                                    href="/login"
                                    className="rounded-md px-3.5 py-2 text-sm/6 font-semibold text-portmain-300 ring-1 ring-inset ring-portmain-300 hover:ring-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-700"
                                >
                                    Go to your account <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
